import React from 'react'
import './comingsoon.css';

export const Comingsoon = () => {
  return (
    <div className='comming-soon'>
    <div >Service not available...Coming soon
    </div>
    <div>Explore other available products...</div>
    </div>
  )
}
